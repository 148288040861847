import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import AccessCardsBox from '@/components/access/section/AccessCardsBox';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const Access: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="アクセス"
      descriptionData="鈴木弘基税理士事務所へお越しの方はこちらをご覧ください。"
      OgImageData="Access"
    />
    <FirstView pageTitle="アクセス" fileName="Access" />
    <AccessCardsBox />
    <BottomMenu />
  </Layout>
);

export default Access;
