import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  accessPaper: {
    padding: `calc(1rem + 1vw)`,
  },
  accessPaperTitle: {
    margin: `0 auto 1.2rem`,
  },
  brAdjust: {
    display: `inline-block`,
  },
  infoBox: {
    margin: `1.2rem auto`,
  },
  infoTitle: {
    margin: `.5rem auto`,
  },
  infoDesc: {
    margin: `0 auto`,
  },
});

const AccessInfoCard: React.VFC = () => {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.accessPaper}>
      <Typography
        variant="h3"
        component="h2"
        className={classes.accessPaperTitle}
      >
        アクセス情報
      </Typography>
      <div className={classes.infoBox}>
        <Typography variant="h4" component="p" className={classes.infoTitle}>
          電車でお越しのお客様へ
        </Typography>
        <Typography className={classes.infoDesc}>
          東武東上線鶴瀬駅より徒歩8分
        </Typography>
      </div>
      <div className={classes.infoBox}>
        <Typography variant="h4" component="p" className={classes.infoTitle}>
          お車でお越しのお客様へ
        </Typography>
        <Typography className={classes.infoDesc}>
          国道254号藤久保交差点より7分
          <br />
          ※事務所横に駐車場（5台）あり
        </Typography>
      </div>
    </Paper>
  );
};

export default AccessInfoCard;
