import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import BottomMenu from '@/components/common/section/BottomMenu';
import AccessInfoCard from '../middle/AccessInfoCard';
import AccessMapCard from '../middle/AccessMapCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AccessCardsBox: {
      padding: `1rem 0`,
      marginTop: `-2rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 20%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const AccessCardsBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.AccessCardsBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12}>
          <AccessInfoCard />
        </Grid>
        <Grid item xs={12}>
          <AccessMapCard />
        </Grid>
      </Grid>
    </section>
  );
};

export default AccessCardsBox;
