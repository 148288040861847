import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  accessPaper: {
    padding: `calc(1rem + 1vw)`,
  },
  accessPaperTitle: {
    margin: `0 auto 1.2rem`,
  },
  map: {
    width: `100%`,
    height: `45vh`,
    border: 0,
  },
});

const AccessMapCard: React.VFC = () => {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.accessPaper}>
      <Typography
        variant="h3"
        component="h2"
        className={classes.accessPaperTitle}
      >
        事務所マップ
      </Typography>
      <iframe
        title="access map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.3313169991357!2d139.53524327160187!3d35.84002273919851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c2d60419b255%3A0xac5ed1647a8f1806!2z6Yi05pyo5byY5Z-656iO55CG5aOr5LqL5YuZ5omA!5e0!3m2!1sja!2sjp!4v1611388864582!5m2!1sja!2sjp"
        className={classes.map}
        frameBorder="0"
        allowFullScreen
        aria-hidden="false"
      />
    </Paper>
  );
};

export default AccessMapCard;
